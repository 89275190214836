.test-submitted-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .return-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .return-button:hover {
    background-color: #0056b3;
  }
  