.container-fluid-i {
  justify-content: center;
  margin-left: 25vh;
  margin-right: 25vh;
  height: 100vh;
  align-items: center;
}

.bcm {
  margin-top: 5vh;
}

/* .cbt-body {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
} */

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

