.form-check-i {
  display: block;
  margin-bottom: 5px;
}

.form-check-input-i {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
