.select-test-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

select {
  padding: 10px;
  margin-bottom: 20px;
}

.test-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.test-info-box {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.start-test-button {
  padding: 10px 20px;
  background-color: #042855;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-test-button:hover{
    background-color: #007bff;
}

.start-test-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
