.btn-holder {
  justify-content: center;
  display: flex;
}

.btn-top {
  border: 1px solid grey;
  transition: 0.5s;
}

.btn-top:hover {
  transform: scale(1.25);
  background-color: #0d6efd;
  color: white;
}
